<template>
  <div class="u-clearfix u-sheet u-sheet-1">
    <h2 class="u-text u-text-1"> {{ $vuetify.lang.t('$vuetify.functions.characteristics.why_as') }}<br>
    </h2>
    <p class="u-large-text u-text u-text-default u-text-variant u-text-2"> {{$vuetify.lang.t('$vuetify.functions.characteristics.answer')}}</p>
    <div class="u-list u-list-1">
      <div class="u-repeater u-repeater-1">
        <div class="u-align-center u-container-style u-list-item u-repeater-item u-list-item-1"
             data-animation-name="bounceIn" data-animation-duration="1000" data-animation-delay="0"
             data-animation-direction="">
          <div class="u-container-layout u-similar-container u-container-layout-1"><span
            class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-1">
            <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 128 128" style=""><use
              xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-54aa"></use></svg>
            <svg class="u-svg-content" viewBox="0 0 128 128" id="svg-54aa"><polygon
              points="128 3 17 3 17 24 25 24 25 11 120 11 120 24 111 24 111 32 120 32 120 95 111 95 111 103 128 103"></polygon><path
              d="m25 24h-25v100h111v-100h-86zm78 92h-95v-63h95v63zm0-71h-95v-13h95v13z"></path><path
              d="m46 74v21h-21v-21h21m8-8h-37v37h37v-37z"></path><rect x="62" y="66" width="32" height="8"></rect><rect
              x="62" y="81" width="32" height="8"></rect><rect x="62" y="95" width="32" height="8"></rect></svg>
          </span>
            <h2 class="u-align-center u-text u-text-3">
              {{ $vuetify.lang.t('$vuetify.functions.functions.functions.title') }}<br>
            </h2>
            <p class="u-align-center u-large-text u-text u-text-variant u-text-4">
              {{ $vuetify.lang.t('$vuetify.functions.functions.functions.text') }}</p>
          </div>
        </div>
        <div class="u-align-center u-container-style u-list-item u-repeater-item u-list-item-2"
             data-animation-name="bounceIn" data-animation-duration="1000" data-animation-delay="0"
             data-animation-direction="">
          <div class="u-container-layout u-similar-container u-container-layout-2"><span
            class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-2">
            <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 128 128" style=""><use
              xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-2323"></use></svg>
            <svg class="u-svg-content" viewBox="0 0 128 128" id="svg-2323"><path
              d="m44.1 63.9h39.8c4.6 0 8.4-3.9 8.4-8.8v-1.8c0-5.6-2.8-10.7-7.3-13.3-2.4-1.4-5.4-2.9-8.8-4.1 2.8-3 4.6-6.9 4.6-11.3-0.1-9.2-7.6-16.6-16.8-16.6s-16.7 7.4-16.7 16.5c0 4.4 1.7 8.4 4.6 11.3-3.4 1.2-6.5 2.7-8.8 4.1-4.5 2.7-7.3 7.7-7.3 13.3v1.8c-0.1 4.9 3.7 8.9 8.3 8.9zm19.9-48c4.8 0 8.7 3.9 8.7 8.6 0 4.8-3.9 8.6-8.7 8.6s-8.7-3.9-8.7-8.6 3.9-8.6 8.7-8.6zm-20.3 37.3c0-2.7 1.3-5.3 3.4-6.5 4-2.3 10.2-5.1 16.9-5.1s12.9 2.8 16.9 5.1c2.1 1.2 3.4 3.8 3.4 6.5v1.8c0 0.6-0.3 0.9-0.4 0.9h-39.8c-0.1 0-0.4-0.3-0.4-0.9v-1.8zm5.5 40.9c-2.4-1.4-5.4-2.9-8.8-4.1 2.8-3 4.6-7 4.6-11.3 0-9.1-7.5-16.5-16.7-16.5s-16.7 7.4-16.7 16.5c0 4.4 1.7 8.4 4.6 11.3-3.4 1.2-6.5 2.7-8.8 4.1-4.6 2.6-7.4 7.7-7.4 13.3v1.8c0 4.9 3.8 8.8 8.4 8.8h39.8c4.6 0 8.4-3.9 8.4-8.8v-1.8c0-5.6-2.8-10.7-7.4-13.3zm-20.9-24.1c4.8 0 8.7 3.9 8.7 8.6 0 4.8-3.9 8.6-8.7 8.6s-8.7-3.9-8.7-8.6 3.9-8.6 8.7-8.6zm20.3 39.2c0 0.6-0.3 0.9-0.4 0.9h-39.8c-0.1 0-0.4-0.3-0.4-0.9v-1.8c0-2.7 1.3-5.3 3.4-6.5 4-2.3 10.2-5.1 16.9-5.1s12.9 2.8 16.9 5.1c2.1 1.2 3.4 3.8 3.4 6.5v1.8zm72.1-15.1c-2.4-1.4-5.4-2.9-8.8-4.1 2.8-3 4.6-6.9 4.6-11.3 0-9.1-7.5-16.5-16.7-16.5s-16.7 7.4-16.7 16.5c-0.1 4.3 1.7 8.3 4.5 11.3-3.4 1.2-6.5 2.7-8.8 4.1-4.5 2.7-7.3 7.7-7.3 13.3v1.8c0 4.9 3.8 8.8 8.4 8.8h39.8c4.6 0 8.4-3.9 8.4-8.8v-1.8c-0.1-5.6-2.9-10.7-7.4-13.3zm-21-24.1c4.8 0 8.7 3.9 8.7 8.6 0 4.8-3.9 8.6-8.7 8.6s-8.7-3.9-8.7-8.6 3.9-8.6 8.7-8.6zm20.3 39.2c0 0.6-0.3 0.9-0.4 0.9h-39.8c-0.1 0-0.4-0.3-0.4-0.9v-1.8c0-2.7 1.3-5.3 3.4-6.5 4-2.3 10.2-5.1 16.9-5.1s12.9 2.8 16.9 5.1c2.1 1.2 3.4 3.8 3.4 6.5v1.8z"></path></svg>
          </span>
            <h2 class="u-align-center u-text u-text-5">
              {{ $vuetify.lang.t('$vuetify.functions.functions.multi_user.title') }}</h2>
            <p class="u-align-center u-large-text u-text u-text-variant u-text-6">
              {{ $vuetify.lang.t('$vuetify.functions.functions.multi_user.text') }}</p>
          </div>
        </div>
        <div class="u-align-center u-container-style u-list-item u-repeater-item u-list-item-3"
             data-animation-name="bounceIn" data-animation-duration="1000" data-animation-delay="0"
             data-animation-direction="">
          <div class="u-container-layout u-similar-container u-container-layout-3"><span
            class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-3"><svg
            class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 60 60" style=""><use
            xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-d83b"></use></svg><svg
            class="u-svg-content" viewBox="0 0 60 60" x="0px" y="0px" id="svg-d83b"
            style="enable-background:new 0 0 60 60;"><g><path
            d="M45,25H20c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S45.552,25,45,25z"></path><path
            d="M20,19h10c0.552,0,1-0.447,1-1s-0.448-1-1-1H20c-0.552,0-1,0.447-1,1S19.448,19,20,19z"></path><path
            d="M45,33H20c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S45.552,33,45,33z"></path><path
            d="M45,41H20c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S45.552,41,45,41z"></path><path
            d="M45,49H20c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S45.552,49,45,49z"></path><path
            d="M49,14.586V0H6v55h5v5h43V19.586L49,14.586z M40,8.414l9,9L50.586,19H40V8.414z M8,53V2h39v10.586L39.414,5H11v48H8z
		 M13,58v-3V7h25v14h14v37H13z"></path>
</g></svg>

          </span>
            <h2 class="u-align-center u-text u-text-7">
              {{ $vuetify.lang.t('$vuetify.functions.functions.facture.title') }}</h2>
            <p class="u-align-center u-large-text u-text u-text-variant u-text-8">
              {{ $vuetify.lang.t('$vuetify.functions.functions.facture.text') }}</p>
          </div>
        </div>
        <div class="u-align-center u-container-style u-list-item u-repeater-item u-list-item-4"
             data-animation-name="bounceIn" data-animation-duration="1000" data-animation-delay="0"
             data-animation-direction="">
          <div class="u-container-layout u-similar-container u-container-layout-4"><span
            class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-4"><svg
            class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 60 60" style=""><use
            xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-1f9b"></use></svg><svg
            class="u-svg-content" viewBox="0 0 60 60" x="0px" y="0px" id="svg-1f9b"
            style="enable-background:new 0 0 60 60;"><g><path
            d="M38.914,0H6.5v60h47V14.586L38.914,0z M39.5,3.414L50.086,14H39.5V3.414z M8.5,58V2h29v14h14v42H8.5z"></path><path
            d="M42.5,21h-16c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1S43.052,21,42.5,21z"></path><path
            d="M22.875,18.219l-4.301,3.441l-1.367-1.367c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l2,2
		C17.987,23.901,18.243,24,18.5,24c0.22,0,0.441-0.072,0.624-0.219l5-4c0.432-0.346,0.501-0.975,0.156-1.406
		C23.936,17.943,23.306,17.874,22.875,18.219z"></path><path
            d="M42.5,32h-16c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1S43.052,32,42.5,32z"></path><path
            d="M22.875,29.219l-4.301,3.441l-1.367-1.367c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l2,2
		C17.987,34.901,18.243,35,18.5,35c0.22,0,0.441-0.072,0.624-0.219l5-4c0.432-0.346,0.501-0.975,0.156-1.406
		C23.936,28.943,23.306,28.874,22.875,29.219z"></path><path
            d="M42.5,43h-16c-0.552,0-1,0.447-1,1s0.448,1,1,1h16c0.552,0,1-0.447,1-1S43.052,43,42.5,43z"></path><path
            d="M22.875,40.219l-4.301,3.441l-1.367-1.367c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l2,2
		C17.987,45.901,18.243,46,18.5,46c0.22,0,0.441-0.072,0.624-0.219l5-4c0.432-0.346,0.501-0.975,0.156-1.406
		C23.936,39.943,23.306,39.874,22.875,40.219z"></path>
</g></svg>

          </span>
            <h2 class="u-align-center u-text u-text-9">
              {{ $vuetify.lang.t('$vuetify.functions.functions.sales.title') }}</h2>
            <p class="u-align-center u-large-text u-text u-text-variant u-text-10">
              {{ $vuetify.lang.t('$vuetify.functions.functions.sales.text') }}</p>
          </div>
        </div>
        <div class="u-align-center u-container-style u-list-item u-repeater-item u-list-item-5"
             data-animation-name="bounceIn" data-animation-duration="1000" data-animation-delay="0"
             data-animation-direction="">
          <div class="u-container-layout u-similar-container u-container-layout-5"><span
            class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-5"><svg
            class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 60 60" style=""><use
            xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-ca7a"></use></svg><svg
            class="u-svg-content" viewBox="0 0 60 60" x="0px" y="0px" id="svg-ca7a"
            style="enable-background:new 0 0 60 60;"><g><path
            d="M47.024,31.5v-8.994c0.043-6.857-4.568-11.405-8.53-13.216C37.359,8.771,36.189,8.371,35,8.074V7.5c0-2.757-2.243-5-5-5   s-5,2.243-5,5v0.661c-1.071,0.289-2.124,0.666-3.146,1.138C17.476,11.317,13.04,16.181,13,22.5v9   c0,6.388-2.256,11.869-6.705,16.291c-0.265,0.264-0.361,0.653-0.249,1.01s0.415,0.621,0.784,0.685l9.491,1.639   c1.768,0.305,3.396,0.555,4.945,0.761c1.745,3.42,5.172,5.615,8.916,5.615c3.745,0,7.173-2.196,8.917-5.618   c1.543-0.205,3.163-0.454,4.921-0.758l9.49-1.639c0.369-0.063,0.671-0.328,0.784-0.685c0.113-0.356,0.017-0.746-0.249-1.01   C49.387,43.16,47.024,37.679,47.024,31.5z M27,7.5c0-1.654,1.346-3,3-3s3,1.346,3,3v0.182c-1.993-0.286-4.015-0.274-6,0.047V7.5z    M30.183,55.5c-2.532,0-4.898-1.258-6.417-3.315c2.235,0.23,4.321,0.346,6.406,0.346c2.093,0,4.186-0.116,6.43-0.349   C35.082,54.241,32.715,55.5,30.183,55.5z M43.681,49.153c-1.919,0.331-3.678,0.6-5.34,0.812c-0.002,0-0.004,0-0.007,0   c-0.733,0.093-1.445,0.174-2.142,0.244c-0.006,0-0.011,0.001-0.017,0.001c-0.639,0.064-1.263,0.116-1.881,0.16   c-0.116,0.008-0.232,0.016-0.347,0.023c-0.535,0.035-1.064,0.063-1.59,0.083c-0.107,0.004-0.215,0.01-0.322,0.013   c-1.244,0.042-2.471,0.042-3.714,0.001c-0.111-0.004-0.223-0.01-0.334-0.014c-0.517-0.021-1.038-0.047-1.565-0.082   c-0.125-0.008-0.25-0.016-0.376-0.025c-0.599-0.043-1.205-0.093-1.824-0.155c-0.023-0.002-0.045-0.004-0.068-0.006   c-0.692-0.069-1.398-0.15-2.124-0.242c-0.003,0-0.006,0-0.009,0c-1.668-0.211-3.434-0.482-5.361-0.814L9,47.83   c3.983-4.554,6-10.038,6-16.33v-8.994c0.034-5.435,3.888-9.637,7.691-11.391c1.131-0.521,2.304-0.91,3.497-1.183   c0.01-0.002,0.021-0.001,0.031-0.003c2.465-0.554,5.087-0.579,7.58-0.068c0.013,0.003,0.026-0.003,0.039-0.001   c1.304,0.272,2.588,0.684,3.825,1.249c3.689,1.687,7.396,5.861,7.361,11.392v9c0,6.033,2.175,11.643,6.313,16.331L43.681,49.153z"></path><path
            d="M36.417,13.838c-3.875-1.771-8.62-1.773-12.469,0.002c-2.195,1.012-5.918,3.973-5.948,8.654   c-0.003,0.552,0.441,1.002,0.994,1.006c0.002,0,0.004,0,0.006,0c0.549,0,0.997-0.443,1-0.994c0.023-3.677,3.019-6.035,4.785-6.85   c3.33-1.537,7.446-1.533,10.799,0c0.503,0.23,1.096,0.009,1.326-0.493C37.14,14.66,36.918,14.067,36.417,13.838z"></path><path
            d="M4.802,22.793c-0.391-0.391-1.023-0.391-1.414,0C1.203,24.978,0,27.886,0,30.983c0,3.097,1.203,6.006,3.388,8.19   c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414C2.995,35.952,2,33.546,2,30.983   c0-2.563,0.995-4.97,2.802-6.776C5.192,23.816,5.192,23.184,4.802,22.793z"></path><path
            d="M8.305,37.19c0.256,0,0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414c-2.558-2.558-2.558-6.719,0-9.276   c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0c-3.337,3.337-3.337,8.768,0,12.104C7.793,37.093,8.049,37.19,8.305,37.19z"></path><path
            d="M56.612,22.793c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414C57.005,26.014,58,28.42,58,30.983   c0,2.563-0.995,4.969-2.802,6.776c-0.391,0.391-0.391,1.023,0,1.414c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293   C58.797,36.989,60,34.08,60,30.983C60,27.886,58.797,24.978,56.612,22.793z"></path><path
            d="M50.988,24.793c-0.391,0.391-0.391,1.023,0,1.414c2.558,2.558,2.558,6.719,0,9.276c-0.391,0.391-0.391,1.023,0,1.414   c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c3.337-3.337,3.337-8.768,0-12.104   C52.011,24.402,51.378,24.402,50.988,24.793z"></path>
</g></svg>

          </span>
            <h2 class="u-align-center u-text u-text-11">
              {{ $vuetify.lang.t('$vuetify.functions.functions.buy.title') }}</h2>
            <p class="u-align-center u-large-text u-text u-text-variant u-text-12">
              {{ $vuetify.lang.t('$vuetify.functions.functions.buy.text') }}</p>
          </div>
        </div>
        <div class="u-align-center u-container-style u-list-item u-repeater-item u-list-item-6"
             data-animation-name="bounceIn" data-animation-duration="1000" data-animation-delay="0"
             data-animation-direction="">
          <div class="u-container-layout u-similar-container u-container-layout-6"><span
            class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-6"><svg
            class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 60 60" style=""><use
            xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-c1d6"></use></svg><svg
            class="u-svg-content" viewBox="0 0 60 60" x="0px" y="0px" id="svg-c1d6"
            style="enable-background:new 0 0 60 60;"><g><path
            d="M15,19h9c0.553,0,1-0.447,1-1s-0.447-1-1-1h-9c-0.553,0-1,0.447-1,1S14.447,19,15,19z"></path><path
            d="M15,26h5c0.553,0,1-0.447,1-1s-0.447-1-1-1h-5c-0.553,0-1,0.447-1,1S14.447,26,15,26z"></path><path
            d="M28,26h7c0.553,0,1-0.447,1-1s-0.447-1-1-1h-7c-0.553,0-1,0.447-1,1S27.447,26,28,26z"></path><path
            d="M24.71,25.71C24.89,25.52,25,25.26,25,25c0-0.261-0.11-0.521-0.29-0.71c-0.38-0.37-1.04-0.37-1.42,0   C23.109,24.479,23,24.739,23,25c0,0.26,0.109,0.52,0.29,0.71C23.479,25.899,23.729,26,24,26C24.26,26,24.52,25.89,24.71,25.71z"></path><path
            d="M39,26c0.27,0,0.52-0.11,0.71-0.29C39.89,25.52,40,25.26,40,25c0-0.261-0.11-0.521-0.29-0.71c-0.38-0.37-1.04-0.37-1.42,0   C38.109,24.479,38,24.739,38,25c0,0.26,0.109,0.52,0.29,0.71C38.479,25.899,38.729,26,39,26z"></path><path
            d="M18,32c0,0.553,0.447,1,1,1h7c0.553,0,1-0.447,1-1s-0.447-1-1-1h-7C18.447,31,18,31.447,18,32z"></path><path
            d="M15.71,32.71C15.899,32.52,16,32.26,16,32c0-0.261-0.101-0.521-0.29-0.71c-0.38-0.37-1.04-0.37-1.42,0   C14.1,31.479,14,31.729,14,32c0,0.26,0.109,0.52,0.29,0.71C14.479,32.89,14.74,33,15,33S15.52,32.89,15.71,32.71z"></path><path
            d="M20.525,37.137c-2.19,1.14-2.927,3.321-3.196,5.582c-0.414-0.347-0.828-0.693-1.241-1.04   c-0.98-0.821-2.402,0.586-1.414,1.415c0.935,0.783,1.871,1.567,2.806,2.351c0.658,0.551,1.676,0.203,1.707-0.707   c0.073-2.166,0.175-4.742,2.348-5.873C22.677,38.269,21.667,36.542,20.525,37.137z"></path><path
            d="M27.175,41.125c-1.385,0.321-2.961,2.083-3.936,0.106c-0.569-1.154-2.295-0.142-1.727,1.009   c0.518,1.049,1.5,1.784,2.669,1.932c0.594,0.075,1.131-0.009,1.688-0.226c0.409-0.159,1.937-1.308,2.267-0.717   c0.628,1.124,2.356,0.117,1.727-1.009C29.326,41.258,28.239,40.878,27.175,41.125z"></path><path
            d="M55.015,0h-4.029c-1.299,0-2.396,0.839-2.805,2H38h-6V1c0-0.553-0.447-1-1-1h-9c-0.553,0-1,0.447-1,1v1h-6H2v58h49v-4.484   l1,1.75V59c0,0.553,0.447,1,1,1s1-0.447,1-1v-1.734l4-7V13V7V2.985C58,1.339,56.661,0,55.015,0z M50,9h6v2h-6V9z M30,2v1v2h-7V3V2   H30z M21,4v2v1h1h9h1V6V4h5v3v1H16V7V4H21z M15,10h23h1V9h5v44H9V9h5v1H15z M49,58H4V4h10v3H7v48h39V7h-7V4h9v3v6v37.266l1,1.75V58   z M50.723,51h4.554L53,54.984l-2-3.5L50.723,51z M56,49h-6V13h6V49z M50,7V2.985C50,2.442,50.442,2,50.985,2h4.029   C55.558,2,56,2.442,56,2.985V7H50z"></path>
</g></svg>

          </span>
            <h2 class="u-align-center u-text u-text-13">
              {{ $vuetify.lang.t('$vuetify.functions.functions.report.title') }}</h2>
            <p class="u-align-center u-large-text u-text u-text-variant u-text-14">
              {{ $vuetify.lang.t('$vuetify.functions.functions.report.text') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemFunctions',
  data () {
    return {
      vBindOption: {
        itemKey: 'name',
        singleExpand: false,
        showExpand: true
      }
    }
  },
  computed: {
    functionsData () {
      return [
        {
          show_plan: true,
          icon: 'mdi-home',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_shop'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_shop')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_shop')
        },
        {
          show_plan: true,
          icon: 'mdi-shopping',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_article'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_article')
        },
        {
          icon: 'mdi-currency-usd',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_vending'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_vending')
        },
        {
          icon: 'mdi-file-document',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_factures'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_facture')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_factures')
        },
        {
          icon: 'mdi-undo',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_refunds'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_refunds')
        },
        {
          icon: 'mdi-database',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_boxes'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_boxes')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_boxes')
        },
        {
          icon: 'mdi-tag',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_category'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_category')
        },
        {
          icon: 'mdi-tag-text-outline',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_mod'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },

          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_mod')
        },
        {
          icon: 'mdi-account-multiple',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_client'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_client')
        },
        {
          icon: 'mdi-cart',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_buy'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_buy')
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.supply_emit'),
          free: true,
          icon: 'mdi-home',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.supply_emit')
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.supply_received'),
          free: true,
          icon: 'mdi-home',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.supply_received')
        },
        {
          icon: 'mdi-book-open-page-variant',
          name: this.$vuetify.lang.t('$vuetify.menu.account_classify'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.account_classify')
        },
        {
          icon: 'mdi-folder',
          name: this.$vuetify.lang.t('$vuetify.menu.accountings'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.accountings')
        },
        {
          icon: 'mdi-account-star',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_employer'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_users')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_employer')
        },
        {
          icon: 'mdi-account-switch',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_supplier'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_supplier')
        },
        {
          icon: 'mdi-account-network',
          name: this.$vuetify.lang.t('$vuetify.menu.affiliate'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.affiliate')
        },
        {
          icon: 'mdi-home-map-marker',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.shop_online'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_shop')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.shop_online')
        },
        {
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.exchange_rate'),
          free: true,
          icon: 'mdi-bank',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.exchange_rate')
        },
        {
          icon: 'mdi-wallet',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.tax_list'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.tax_list')
        },
        {
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.discount'),
          free: true,
          icon: 'mdi-cash-minus',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.discounts')
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div
    class="u-clearfix u-sheet u-valign-middle-lg u-valign-middle-md u-valign-middle-sm u-valign-middle-xl u-sheet-1">
    <div
      class="u-clearfix u-expanded-width-lg u-expanded-width-md u-expanded-width-sm u-expanded-width-xl u-gutter-0 u-layout-wrap u-layout-wrap-1">
      <div class="u-gutter-0 u-layout">
        <div class="u-layout-col">
          <div class="u-size-30">
            <div class="u-layout-col">
              <div class="u-align-left u-container-style u-layout-cell u-size-60 u-layout-cell-1">
                <div class="u-container-layout u-valign-top u-container-layout-1">
                  <h2 class="u-text u-text-1">
                    {{ $vuetify.lang.t('$vuetify.functions.about_as.title') }}<br>
                  </h2>
                  <p class="u-align-justify u-large-text u-text u-text-variant u-text-2">{{ $vuetify.lang.t('$vuetify.functions.about_as.text') }}<br>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="u-size-30">
            <div class="u-layout-row">
              <div class="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
                <div class="u-container-layout u-valign-middle u-container-layout-2">
                  <ol class="u-text u-text-3">
                    <li>&nbsp;{{ $vuetify.lang.t('$vuetify.functions.about.one') }}</li>
                    <li>{{ $vuetify.lang.t('$vuetify.functions.about.two') }}</li>
                    <li>{{ $vuetify.lang.t('$vuetify.functions.about.three') }}</li>
                    <li>{{ $vuetify.lang.t('$vuetify.functions.about.four') }}<br>
                    </li>
                    <li>{{ $vuetify.lang.t('$vuetify.functions.about.five') }}<br>
                    </li>
                  </ol>
                </div>
              </div>
              <div class="u-container-style u-layout-cell u-size-30 u-layout-cell-3">
                <div class="u-container-layout u-valign-middle-xs u-container-layout-3">
                  <img class="u-image u-image-round u-radius-11 u-image-1"
                       src="../../assets/images/time.jpg" data-image-width="3024"
                       data-image-height="4032">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#about {
  /*background-color: #8BC7C7;*/
    background-image: url("/assets/background/downloadSection.jpg");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 500px;
}
</style>

<script>

export default {
  data: () => ({
    icons: ['fa-facebook', 'fa-twitter', 'fa-linkedin', 'fa-instagram'],
    valid: true,
    name: '',
    lazy: false
  }),
  computed: {
    getCharacteristic () {
      return [
        {
          title: this.$vuetify.lang.t('$vuetify.access.access.manager_vending'),
          text: this.$vuetify.lang.t('$vuetify.texts.sale_characteristic')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.access.access.manager_employer'),
          text: this.$vuetify.lang.t('$vuetify.texts.employer_characteristic')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.access.access.manager_boxes'),
          text: this.$vuetify.lang.t('$vuetify.texts.boxes_characteristic')
        },
        {
          title: this.$vuetify.lang.t('$vuetify.access.access.manager_factures'),
          text: this.$vuetify.lang.t('$vuetify.texts.facture_characteristic')
        }
      ]
    }
  }
}
</script>

<style>

.svg-border-waves img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: -2px;
    z-index: -1;
}

.card {
    min-height: 200px;
    padding: 10px;
    transition: 0.5s ease-out;
}

.card .v-image {
    margin-bottom: 15px;
    transition: 0.75s;
}

.card h1 {
    margin-bottom: 10px;
}

.zoom-efect {
    transform: scale(1.1);
}

.up {
    transform: translateY(-20px);
    transition: 0.5s ease-out;
}
</style>

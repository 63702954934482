<template>
  <div class="u-clearfix u-sheet u-sheet-1">
    <div class="u-clearfix u-expanded-width u-gutter-0 u-layout-wrap u-layout-wrap-1">
      <div class="u-gutter-0 u-layout">
        <div class="u-layout-row">
          <div
            class="u-align-left u-container-style u-layout-cell u-shape-rectangle u-size-27-lg u-size-27-xl u-size-30-md u-size-30-sm u-size-30-xs u-layout-cell-1">
            <div
              class="u-container-layout u-valign-middle-lg u-valign-middle-xl u-container-layout-1">
              <p class="u-align-justify u-text u-text-1"> {{ $vuetify.lang.t('$vuetify.functions.suport') }}</p>
              <h2 class="u-text u-text-2">{{ $vuetify.lang.t('$vuetify.functions.follow') }}</h2>
              <div class="u-align-left u-social-icons u-spacing-10 u-social-icons-1">
                <a class="u-social-url" target="_blank"
                   href="https://facebook.com/facebook.com"><span
                  class="u-icon u-icon-circle u-social-facebook u-social-icon u-text-black u-icon-1">
                    <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style=""><use
                      xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-87ce"></use></svg>
                    <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-87ce" class="u-svg-content"><path
                      d="M75.5,28.8H65.4c-1.5,0-4,0.9-4,4.3v9.4h13.9l-1.5,15.8H61.4v45.1H42.8V58.3h-8.8V42.4h8.8V32.2 c0-7.4,3.4-18.8,18.8-18.8h13.8v15.4H75.5z"></path></svg>
                  </span>
                </a>
                <a class="u-social-url" target="_blank"
                   href="https://twitter.com/twitter.com"><span
                  class="u-icon u-icon-circle u-social-icon u-social-twitter u-text-black u-icon-2">
                    <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style=""><use
                      xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-88a8"></use></svg>
                    <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-88a8" class="u-svg-content"><path
                      d="M92.2,38.2c0,0.8,0,1.6,0,2.3c0,24.3-18.6,52.4-52.6,52.4c-10.6,0.1-20.2-2.9-28.5-8.2 c1.4,0.2,2.9,0.2,4.4,0.2c8.7,0,16.7-2.9,23-7.9c-8.1-0.2-14.9-5.5-17.3-12.8c1.1,0.2,2.4,0.2,3.4,0.2c1.6,0,3.3-0.2,4.8-0.7 c-8.4-1.6-14.9-9.2-14.9-18c0-0.2,0-0.2,0-0.2c2.5,1.4,5.4,2.2,8.4,2.3c-5-3.3-8.3-8.9-8.3-15.4c0-3.4,1-6.5,2.5-9.2 c9.1,11.1,22.7,18.5,38,19.2c-0.2-1.4-0.4-2.8-0.4-4.3c0.1-10,8.3-18.2,18.5-18.2c5.4,0,10.1,2.2,13.5,5.7c4.3-0.8,8.1-2.3,11.7-4.5 c-1.4,4.3-4.3,7.9-8.1,10.1c3.7-0.4,7.3-1.4,10.6-2.9C98.9,32.3,95.7,35.5,92.2,38.2z"></path></svg>
                  </span>
                </a>
                <a class="u-social-url" target="_blank"
                   href="https://www.instagram.com/instagram.com"><span
                  class="u-icon u-icon-circle u-social-icon u-social-instagram u-text-black u-icon-3">
                    <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style=""><use
                      xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-694e"></use></svg>
                    <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-694e" class="u-svg-content"><path
                      d="M55.9,32.9c-12.8,0-23.2,10.4-23.2,23.2s10.4,23.2,23.2,23.2s23.2-10.4,23.2-23.2S68.7,32.9,55.9,32.9z M55.9,69.4c-7.4,0-13.3-6-13.3-13.3c-0.1-7.4,6-13.3,13.3-13.3s13.3,6,13.3,13.3C69.3,63.5,63.3,69.4,55.9,69.4z"></path><path
                      d="M79.7,26.8c-3,0-5.4,2.5-5.4,5.4s2.5,5.4,5.4,5.4c3,0,5.4-2.5,5.4-5.4S82.7,26.8,79.7,26.8z"></path><path
                      d="M78.2,11H33.5C21,11,10.8,21.3,10.8,33.7v44.7c0,12.6,10.2,22.8,22.7,22.8h44.7c12.6,0,22.7-10.2,22.7-22.7 V33.7C100.8,21.1,90.6,11,78.2,11z M91,78.4c0,7.1-5.8,12.8-12.8,12.8H33.5c-7.1,0-12.8-5.8-12.8-12.8V33.7 c0-7.1,5.8-12.8,12.8-12.8h44.7c7.1,0,12.8,5.8,12.8,12.8V78.4z"></path></svg>
                  </span>
                </a>
                <a class="u-social-url" target="_blank"
                   href="https://linkedin.com/in/linkedin.com"><span
                  class="u-icon u-icon-circle u-social-icon u-social-linkedin u-text-black u-icon-4">
                    <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style=""><use
                      xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-1f32"></use></svg>
                    <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-1f32" class="u-svg-content"><path
                      d="M33.8,96.8H14.5v-58h19.3V96.8z M24.1,30.9L24.1,30.9c-6.6,0-10.8-4.5-10.8-10.1c0-5.8,4.3-10.1,10.9-10.1 S34.9,15,35.1,20.8C35.1,26.4,30.8,30.9,24.1,30.9z M103.3,96.8H84.1v-31c0-7.8-2.7-13.1-9.8-13.1c-5.3,0-8.5,3.6-9.9,7.1 c-0.6,1.3-0.6,3-0.6,4.8V97H44.5c0,0,0.3-52.6,0-58h19.3v8.2c2.6-3.9,7.2-9.6,17.4-9.6c12.7,0,22.2,8.4,22.2,26.1V96.8z"></path></svg>
                  </span>
                </a>
                <a class="u-social-url" target="_blank"
                   href="https://pinterest.com/pinterest.com"><span
                  class="u-icon u-icon-circle u-social-icon u-social-pinterest u-text-black u-icon-5">
                    <svg class="u-svg-link" preserveAspectRatio="xMidYMin slice" viewBox="0 0 112 112" style=""><use
                      xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-9623"></use></svg>
                    <svg x="0px" y="0px" viewBox="0 0 112 112" id="svg-9623" class="u-svg-content"><path
                      d="M61.9,79.8c-5.5-0.3-7.8-3.1-12-5.8c-2.3,12.4-5.4,24.3-13.8,30.5c-2.6-18.7,3.8-32.8,6.9-47.7 c-5.1-8.7,0.7-26.2,11.5-21.9c13.5,5.4-11.6,32.3,5.1,35.7c17.6,3.5,24.7-30.5,13.8-41.4c-15.7-16.1-45.7-0.5-42,22.3 c0.9,5.6,6.7,7.2,2.3,15c-10.1-2.2-13-10.2-12.7-20.7c0.6-17.3,15.5-29.3,30.5-31.1c19-2.2,36.8,6.9,39.2,24.7 C93.4,59.5,82.3,81.3,61.9,79.8z"></path></svg>
                  </span>
                </a>
              </div>
              <p class="u-text u-text-black u-text-3">©2021 Privacy policy</p>
            </div>
          </div>
          <div
            class="u-align-left u-container-style u-custom-color-1 u-layout-cell u-radius-16 u-shape-round u-size-30-md u-size-30-sm u-size-30-xs u-size-33-lg u-size-33-xl u-layout-cell-2">
            <div class="u-container-layout u-container-layout-2">
              <h2 class="u-text u-text-4">
                {{ $vuetify.lang.t('$vuetify.functions.say').toUpperCase() }}</h2>
              <p class="u-large-text u-text u-text-variant u-text-5">
                {{ $vuetify.lang.t('$vuetify.texts.note_beneficiaries') }}</p>
              <div class="u-expanded-width u-form u-form-1">
                <form action="#" method="POST"
                      class="u-clearfix u-form-spacing-30 u-form-vertical u-inner-form"
                      style="padding: 0px;" source="custom" name="form">
                  <div class="u-form-group u-form-name">
                    <label for="name-3b9a" class="u-form-control-hidden u-label"
                           wfd-invisible="true">{{$vuetify.lang.t('$vuetify.firstName')}}</label>
                    <input type="text" :placeholder="$vuetify.lang.t('$vuetify.firstName')" id="name-3b9a"
                           name="name"
                           class="u-border-2 u-border-white u-input u-input-rectangle u-radius-16 u-white u-input-1"
                           required="">
                  </div>
                  <div class="u-form-email u-form-group">
                    <label for="email-3b9a" class="u-form-control-hidden u-label"
                           wfd-invisible="true">{{$vuetify.lang.t('$vuetify.email')}}</label>
                    <input type="email" :placeholder="$vuetify.lang.t('$vuetify.email')"
                           id="email-3b9a" name="email"
                           class="u-border-2 u-border-white u-input u-input-rectangle u-radius-16 u-white u-input-2"
                           required="">
                  </div>
                  <div class="u-form-group u-form-message">
                    <label for="message-3b9a" class="u-form-control-hidden u-label"
                           wfd-invisible="true">{{$vuetify.lang.t('$vuetify.description')}}</label>
                    <textarea :placeholder="$vuetify.lang.t('$vuetify.description')" rows="4" cols="50"
                              id="message-3b9a" name="message"
                              class="u-border-2 u-border-white u-input u-input-rectangle u-radius-16 u-white u-input-3"
                              required=""></textarea>
                  </div>
                  <div class="u-align-left u-form-group u-form-submit">
                    <v-btn @click="sendEmail" class="u-border-2 u-border-black u-btn u-btn-round u-btn-submit u-button-style u-hover-black u-none u-radius-50 u-text-black u-text-hover-white u-btn-1">Submit</v-btn>
                  </div>
                  <v-alert
                    v-model="showContactUsOk"
                    style="margin-top: 15px !important; margin-left: 30px !important; width: 100%"
                    dismissible
                    color="cyan"
                    border="left"
                    elevation="2"
                    colored-border
                    icon="mdi-check"
                  >
                    Thank you! Your message has been sent.
                  </v-alert>
                  <v-alert
                    style="margin-top: 15px !important; margin-left: 30px !important; width: 100%"
                    v-model="showContactUsError"
                    dismissible
                    color="error"
                    border="left"
                    elevation="2"
                    colored-border
                  >
                    Unable to send your message. Please fix errors then try again.
                  </v-alert>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    icons: ['fa-facebook', 'fa-twitter', 'fa-linkedin', 'fa-instagram'],
    valid: true,
    showContactUsOk: false,
    showContactUsError: false,
    name: '',
    nameRules: [
      (v) => !!v || 'Campo nombre es requerido',
      (v) => (v && v.length >= 6) || 'Nombre requiere terner más de 6 caracteres'
    ],
    email: 'inneli@gmail.com',
    emailRules: [
      (v) => !!v || 'Campo email es requerido',
      (v) => /.+@.+\..+/.test(v) || 'E-mail requiere ser válido'
    ],
    textArea: '',
    textAreaRules: [
      (v) => !!v || 'Campo de texto es requerido',
      (v) => (v && v.length >= 10) || 'Mínimo de 10 carácteres'
    ],
    lazy: false
  }),
  computed: {
    getPhone () {
      return process.env.VUE_APP_PHONE_SUPPORT
    }
  },
  methods: {
    ...mapActions('auth', ['sendContact']),
    async sendEmail () {
      this.sendContact({
        name: $('#name-3b9a')[0].value, from: $('#email-3b9a')[0].value, info: $('#message-3b9a')[0].value
      }).then(() => {
        $('#name-3b9a')[0].value = ''
        $('#email-3b9a')[0].value = ''
        $('#message-3b9a')[0].value = ''
        this.showContactUsOk = true
        this.showContactUsError = false
      }).catch(() => {
        this.showContactUsOk = false
        this.showContactUsError = true
      })
    }
  }
}
</script>

<template>
  <div style="margin-top: 15px">
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      src="/assets/img/bgDrawer.jpg"
    >
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              src="/assets/base_icon_transparent.png"
              alt="Logo"
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="title white--tex tnotranslate" content="notranslate" name="google" translate="no">
              Billing Money App
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider/>

      <v-list dense>
        <v-list-item
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          class="white--text"
          @click="$vuetify.goTo(link)"
        >
          <v-list-item-icon class="justify-center">
            <v-icon color="white">{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{
                text
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!existToken"
          link
          @click="goToLogin"
        >
          <v-list-item-icon class="justify-center">
            <v-icon color="white">mdi-fingerprint</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ $vuetify.lang.t('$vuetify.login') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!existToken"
          link
          @click="goToRegister"
        >
          <v-list-item-icon class="justify-center">
            <v-icon color="white">mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ $vuetify.lang.t('$vuetify.register') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="existToken"
          link
          @click="goToDashboard"
        >
          <v-list-item-icon class="justify-center">
            <v-icon color="white">mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">
              {{ $vuetify.lang.t('$vuetify.menu.dashboard') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
        >

          <v-select
            id="menuLang"
            v-model="$vuetify.lang.current"
            :items="availableLanguages"
            class="white--text"
            item-text="text"
            item-value="value"
            menu-props="auto"
            style="width:6em"
            @change="handleChangeLocale($event)"
          />
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :flat="flat"
      class="px-15"
      :class="{ expand: flat }"
    >
      <v-toolbar-title>
      </v-toolbar-title>
      <v-spacer/>
      <v-app-bar-nav-icon
        v-if="isXs"
        class="mr-4"
        @click.stop="drawer = !drawer"
      />
      <div v-else class="u-menu u-menu-one-level u-offcanvas u-menu-1">
        <v-btn
          v-for="([icon,text, link], i) in items"
          :key="i"
          text
          style="padding: 10px 20px; text-shadow: 0 2px 8px rgba(128,128,128,1);"
          @click="$vuetify.goTo(link)"
        >
          <v-icon style="display:none">
            {{ icon }}
          </v-icon>
          <h5 class="mr-2">{{ text }}</h5>
        </v-btn>
        <v-btn
          v-if="!existToken"
          text
          style="padding: 10px 20px; text-shadow: 0 2px 8px rgba(128,128,128,1);"
          @click="goToLogin"
        >
          <h5 class="mr-2">
            {{ $vuetify.lang.t('$vuetify.login') }}
          </h5>
        </v-btn>
        <v-btn
          v-if="!existToken"
          text
          style="padding: 10px 20px; text-shadow: 0 2px 8px rgba(128,128,128,1);"
          @click="goToRegister"
        >
          <h5 class="mr-2">
            {{ $vuetify.lang.t('$vuetify.register') }}</h5>
        </v-btn>
        <v-btn
          v-if="existToken"
          text
          class="withe--text"
          @click="goToDashboard"
        >
          <h5 class="mr-2" style="text-shadow: 0 2px 8px rgba(128,128,128,1); ">
              {{ $vuetify.lang.t('$vuetify.menu.dashboard') }}
          </h5>
        </v-btn>

        <v-btn
          icon
          :style="$vuetify.lang.current === 'es' ? 'background-color: #85e0e6' : ''"
          class="withe--text"
          @click="handleChangeLocale('es')"
        >
          <country-flag country='es' size='normal' style="margin-bottom: 0px !important;"/>
        </v-btn>
        <v-btn
          icon
          :style="$vuetify.lang.current === 'en' ? 'background-color: #85e0e6' : ''"
          class="withe--text"
          @click="handleChangeLocale('en')"
        >
          <country-flag country='us' size='normal' style="margin-bottom: 0px !important;"/>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import localStorage from '../../config/localStorage'
import { mapGetters, mapState } from 'vuex'
import CountryFlag from 'vue-country-flag'

export default {
  props: {
    color: {
      type: String,
      default: ''
    },
    flat: Boolean
  },
  data () {
    return {
      drawer: null,
      isXs: false,
      items: [
        ['mdi-home-outline', 'Home', '#sec-home'],
        ['mdi-information-outline', this.$vuetify.lang.t('$vuetify.menu.about'), '#sec-about'],
        ['mdi-currency-usd', this.$vuetify.lang.t('$vuetify.menu.prices'), '#sec-price'],
        ['mdi-settings', this.$vuetify.lang.t('$vuetify.functions.text'), '#sec-characteristics'],
        ['mdi-account-card-details', 'Partner', '#sec-partner'],
        ['mdi-email-outline', this.$vuetify.lang.t('$vuetify.menu.contact'), '#sec-contact']
      ]
    }
  },
  components: {
    CountryFlag
  },
  computed: {
    ...mapState('auth', ['isLoggedIn', 'userData']),
    ...mapGetters('auth', ['isManagerIn', 'isAdminIn', 'pinSuccess', 'user']),
    existToken () {
      return !!localStorage.getToken()
    },
    availableLanguages () {
      const { locales } = this.$vuetify.lang
      return Object.keys(locales).map((lang) => {
        return {
          text: locales[lang].label,
          value: lang
        }
      })
    }
  },
  watch: {
    isXs (value) {
      if (!value) {
        if (this.drawer) {
          this.drawer = false
        }
      }
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    handleChangeLocale (value) {
      this.$vuetify.lang.current = value
      localStorage.setLanguage(value)
      window.location.reload()
    },
    onResize () {
      this.isXs = window.innerWidth < 850
    },
    goToLogin () {
      this.$router.push({ name: 'login' })
    },
    goToRegister () {
      if (this.$route.params.affiliate_id) {
        this.$router.push({ name: 'invitation_password', params: { hash: this.$route.params.affiliate_id } })
      } else {
        this.$router.push({ name: 'register' })
      }
    },
    goToDashboard () {
      if (localStorage.getTypeConnection() === 'partner') {
        this.$router.push({ name: 'partner_dashboard' })
      } else if (localStorage.getTypeConnection() === 'beneficiary') {
        this.$router.push({ name: 'beneficiary_dashboard' })
      } else if ((localStorage.getTypeConnection() === 'admin' || localStorage.getTypeConnection() === 'user') && this.isLoggedIn && this.isAdminIn) {
        this.$router.push({ name: 'admin_dashboard' })
      } else if (this.isLoggedIn && this.isManagerIn) {
        this.$router.push({ name: 'dashboard' })
      } else if (this.isLoggedIn && !this.isManagerIn) {
        this.$router.push({ name: 'dashboard' })
      }
    }
  }
}
</script>

<style scoped>

.v-toolbar {
  /*transition: 0.6s;*/
  /*  background-color: #8BC7C7;*/

}

.expand {
  height: 80px !important;
  padding-top: 10px;
}

</style>

<template>
  <div class="u-clearfix u-sheet u-sheet-1">
    <h2 class="u-text u-text-default u-text-1"> {{
        $vuetify.lang.t('$vuetify.texts.choice_plan_text')
      }}</h2>
    <div class="u-clearfix u-gutter-48 u-layout-wrap u-layout-wrap-1">
      <div class="u-gutter-0 u-layout">
        <div class="u-layout-row">
          <div class="u-container-style u-layout-cell u-radius-50 u-size-30 u-layout-cell-1">
            <div class="u-border-1 u-border-grey-30 u-container-layout u-container-layout-1">
              <h3 class="u-align-center u-text u-text-2">
                <b>{{ $vuetify.lang.t('$vuetify.plan.type.basic') }}</b></h3>
              <h3 class="u-align-center u-text u-text-2">
                <b>{{basicPlan ? basicPlan.facture_period.month : '28.00 USD'}} {{basicPlan ? basicPlan.currency : 'USD'}} </b></h3>
              <p class="u-align-center u-large-text u-text u-text-variant u-text-3">
                {{ getCharacteristics }} </p>
              <p class="u-align-center u-large-text u-text u-text-variant u-text-3">
                {{ $vuetify.lang.t('$vuetify.texts.basic_characteristics2') }} </p>
              <p class="u-align-center u-large-text u-text u-text-variant u-text-3">
                {{ $vuetify.lang.t('$vuetify.texts.basic_characteristics3') }} </p>
              <div  v-for="(v, i) in planLimitations" :key=i>
                <div v-if="v.show_plan"
                     class="u-border-1 u-border-grey-dark-1 u-line u-line-horizontal u-opacity u-opacity-30 u-line-1"></div>
                <p v-if="v.show_plan" class="u-align-center u-text u-text-4">{{ v.name }}</p>
                <p v-if="v.show_plan" class="u-align-center u-text u-text-4">
                  (<v-icon
                  :style="v.basic.state === true?'color: green':'color: red'"
                  class="mr-2"
                  small
                >
                  {{v.basic.state === true ? 'mdi-check-circle': 'mdi-alert' }}
                </v-icon>
                  {{v.basic.description}})</p>
              </div>
            </div>
          </div>
          <div
            class="u-align-center u-container-style u-layout-cell u-radius-50 u-size-30 u-layout-cell-2">
            <div
              class="u-border-1 u-border-grey-30 u-container-layout u-valign-bottom u-container-layout-2">
              <h3 class="u-align-center u-text u-text-14">
                <b>{{ $vuetify.lang.t('$vuetify.plan.type.unicorn') }}</b></h3>
              <h3 class="u-align-center u-text u-text-2">
                <b>{{unicornPlan ? unicornPlan.facture_period.month : '39.40 USD'}} {{unicornPlan ? unicornPlan.currency : 'USD'}} </b></h3>
              <p class="u-align-center u-large-text u-text u-text-variant u-text-3">
                {{ $vuetify.lang.t('$vuetify.texts.unicorn_characteristics1') }} </p>
              <p class="u-align-center u-large-text u-text u-text-variant u-text-3">
                {{ $vuetify.lang.t('$vuetify.texts.unicorn_characteristics2') }} </p>
              <p class="u-align-center u-large-text u-text u-text-variant u-text-3">
                {{ $vuetify.lang.t('$vuetify.texts.unicorn_characteristics3') }} </p>
              <div  v-for="(v, i) in planLimitations" :key=i>
                <div v-if="v.show_plan"
                     class="u-border-1 u-border-grey-dark-1 u-line u-line-horizontal u-opacity u-opacity-30 u-line-1"></div>
                <p v-if="v.show_plan" class="u-align-center u-text u-text-4">{{ v.name }}</p>
                <p v-if="v.show_plan" class="u-align-center u-text u-text-4">
                  (<v-icon
                  :style="v.unicorn.state === true?'color: green':'color: red'"
                  class="mr-2"
                  small
                >
                  {{v.unicorn.state === true ? 'mdi-check-circle': 'mdi-alert' }}
                </v-icon>
                  {{v.unicorn.description}})</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data: () => ({
    // basicPlan: null,
    // unicornPlan: null,
    // basicRestriction: null
  }),
  computed: {
    ...mapState('plan', ['restrictionPlan']),
    ...mapState('auth', ['userPin']),
    ...mapGetters('auth', ['user']),
    size () {
      const size = { md: 'large', xl: 'x-large' }[
        this.$vuetify.breakpoint.name
      ]
      return size ? { [size]: true } : {}
    },
    getCharacteristics () {
      // console.log(this.basicPlan.basic_limit ? this.$vuetify.lang.t('$vuetify.texts.basic_characteristics.limit', [[this.basicRestriction], [this.getCurrency]])
      //   : this.$vuetify.lang.t('$vuetify.texts.basic_characteristics.no_limit'))
      return this.basicRestriction ? this.$vuetify.lang.t('$vuetify.texts.basic_characteristics.limit', [this.basicRestriction], [this.getCurrency])
        : this.$vuetify.lang.t('$vuetify.texts.basic_characteristics.no_limit')
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    basicPlan () {
      return this.restrictionPlan ? this.restrictionPlan.filter(plan => plan.type === 'basic')[0] : null
    },
    unicornPlan () {
      return this.restrictionPlan ? this.restrictionPlan.filter(plan => plan.type === 'unicorn')[0] : null
    },
    basicRestriction () {
      if (this.basicPlan) {
        const countries = JSON.parse(this.basicPlan.countries)
        let basic = null
        countries.forEach((country) => {
          if (country.country.id === this.user.country) {
            basic = country.basic_limit
          }
        })
        return basic
      }
      return null
    },
    planLimitations () {
      return [
        {
          show_plan: true,
          icon: 'mdi-shopping',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_article'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_article')
        },
        {
          icon: 'mdi-currency-usd',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_vending'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_vending')
        },
        {
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.exchange_rate'),
          free: true,
          icon: 'mdi-bank',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.exchange_rate')
        },
        {
          icon: 'mdi-wallet',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.tax_list'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.tax_list')
        },
        {
          icon: 'mdi-database',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_boxes'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_boxes')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_boxes')
        },
        {
          show_plan: true,
          icon: 'mdi-home',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_shop'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_shop')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_shop')
        }, {
          icon: 'mdi-account-star',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_employer'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_users')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_employer')
        },
        {
          icon: 'mdi-home-map-marker',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.shop_online'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_shop')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.shop_online')
        }
      ]
    },
    getPlans () {
      return [
        {
          title: this.$vuetify.lang.t('$vuetify.plan.type.free'),
          characteristics: [this.$vuetify.lang.t('$vuetify.texts.free_characteristics1'),
            this.$vuetify.lang.t('$vuetify.texts.free_characteristics2'),
            this.$vuetify.lang.t('$vuetify.texts.free_characteristics3')]
        },
        {
          title: this.$vuetify.lang.t('$vuetify.plan.type.basic'),
          characteristics: [this.$vuetify.lang.t('$vuetify.texts.basic_characteristics1'),
            this.$vuetify.lang.t('$vuetify.texts.basic_characteristics2'),
            this.$vuetify.lang.t('$vuetify.texts.basic_characteristics3')]
        },
        {
          title: this.$vuetify.lang.t('$vuetify.plan.type.unicorn'),
          characteristics: [this.$vuetify.lang.t('$vuetify.texts.unicorn_characteristics1'),
            this.$vuetify.lang.t('$vuetify.texts.unicorn_characteristics2'),
            this.$vuetify.lang.t('$vuetify.texts.unicorn_characteristics3')]
        }
      ]
    }
  },
  async mounted () {
    await this.getUserData()
    await this.getCountryPlan()
  },
  methods: {
    ...mapActions('auth', ['getUserData']),
    ...mapActions('plan', ['getRestrictions']),
    async getCountryPlan () {
      let data = null
      await fetch('https://ip2c.org/s')
        .then(response => response.text())
        .then((response) => {
          const result = (response || '').toString()

          if (!result || result[0] !== '1') {
            throw new Error('unable to fetch the country')
          }
          data = result.substr(2, 2)
        }).catch(() => {
          this.getRestrictions({ country: null })
        })
      if (data) { this.getRestrictions({ country: data }) }
    }

  }
}
</script>

<style lang="scss">
$main_color: #283e79;

ul {
  font-size: 13px;
  line-height: 1.5em;
  margin: 5px 0 15px;
  padding: 0;

  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }

  li {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 5px;
      width: 10px;
      height: 10px;
      background-color: $main_color;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-eeborder-radius: 50%;
    }
  }
}
</style>

<style scoped>
.header {
  background-color: #283e79;
  color: white;
}

.circle1 {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-color: #f0f8ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle2 {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: #e0effc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-border-rounded svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #f4f7f5;
  z-index: -1;
}

#pricing {
  z-index: 0;
}

.content {
  z-index: 1;
}

svg {
  overflow: hidden;
}

section {
  position: relative;
}
#pricing {
    background-color: #8BC7C7;
}
</style>

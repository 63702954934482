<template>
  <div>
    <navigation
      :color="color"
      :flat="flat"/>
    <section class="u-clearfix u-image u-shading u-typography-Normal--Introduction u-section-1" src="" id="sec-home"
             data-image-width="974" data-image-height="491">
      <div class="u-clearfix u-sheet u-sheet-1">
        <div
          class="u-align-left u-container-style u-expanded-width-sm u-expanded-width-xs u-group u-similar-fill u-group-1">
          <div class="u-container-layout u-valign-middle-xs u-container-layout-1">
            <h1 class="u-text u-text-1 notranslate" content="notranslate" name="google" translate="no">Billing Money App<br>
            </h1>
            <h5 class="u-custom-font u-heading-font u-text u-text-body-alt-color u-text-3">
              {{ $vuetify.lang.t('$vuetify.slogan_bill') }}<br>
            </h5>
          </div>
        </div>
      </div>
    </section>
    <section class="u-clearfix u-section-2" id="sec-064b">
        <header-section/>
    </section>
    <section class="sec-about u-clearfix u-white u-section-3" id="sec-about">
      <about-section/>
    </section>
    <section class="u-align-center u-clearfix u-section-4" id="sec-price">
      <pricing-section/>
    </section>
    <section class="u-clearfix u-section-5" id="sec-dc6c">
      <div class="u-clearfix u-sheet u-sheet-1">
        <div
          class="u-container-style u-custom-color-1 u-expanded-width u-group u-radius-50 u-shape-round u-group-1">
          <div class="u-container-layout u-valign-top u-container-layout-1">
            <p class="u-align-center u-text u-text-1">
              <b>{{ $vuetify.lang.t('$vuetify.plan.type.personalized') }}</b></p>
            <p class="u-align-center u-text u-text-2">
              {{ $vuetify.lang.t('$vuetify.texts.personalized_characteristics') }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="u-align-center u-clearfix u-section-6" id="sec-characteristics">
      <system-functions/>
    </section>
    <section class="u-align-center u-clearfix u-white u-section-7" id="sec-24a6">
      <div class="u-clearfix u-sheet u-sheet-1">
        <div class="u-expanded-width u-list u-list-1">
          <div class="u-repeater u-repeater-1">
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-white u-list-item-1">
              <div class="u-container-layout u-similar-container u-container-layout-1"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-1">
                                <v-icon x-large color="white"> {{ functionsData[0].icon }}</v-icon>
                            </span>
                <h4 class="u-align-center u-text u-text-default u-text-1">
                  {{ functionsData[0].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-2">
                  {{ functionsData[0].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-2">
              <div class="u-container-layout u-similar-container u-container-layout-2"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-2">
                                <v-icon x-large color="white"> {{ functionsData[1].icon }}</v-icon>
                            </span>
                <h4 class="u-align-center u-text u-text-default u-text-3">{{ functionsData[1].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-4"> {{ functionsData[1].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-3">
              <div class="u-container-layout u-similar-container u-container-layout-3"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-3">
                                <v-icon x-large color="white"> {{ functionsData[2].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-5">{{ functionsData[2].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-6"> {{ functionsData[2].description }}
                </p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-4">
              <div class="u-container-layout u-similar-container u-container-layout-4"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-4">
                                <v-icon x-large color="white"> {{ functionsData[3].icon }}</v-icon>
                            </span>
                <h4 class="u-align-center u-text u-text-default u-text-7">{{ functionsData[3].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-8"> {{ functionsData[3].description }} </p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-5">
              <div class="u-container-layout u-similar-container u-container-layout-5"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-5">
                                <v-icon x-large color="white"> {{ functionsData[4].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-9">{{ functionsData[4].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-10">{{ functionsData[4].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-6">
              <div class="u-container-layout u-similar-container u-container-layout-6"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-6">

                                <v-icon x-large color="white"> {{ functionsData[5].icon }}</v-icon>
                            </span>
                <h4 class="u-align-center u-text u-text-default u-text-11">{{ functionsData[5].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-12">{{ functionsData[5].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-7">
              <div class="u-container-layout u-similar-container u-container-layout-7"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-7">
                                <v-icon x-large color="white"> {{ functionsData[6].icon }}</v-icon>
                            </span>
                <h4 class="u-align-center u-text u-text-default u-text-13">{{ functionsData[6].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-14">{{ functionsData[6].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-8">
              <div class="u-container-layout u-similar-container u-container-layout-8"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-8">
                                <v-icon x-large color="white"> {{ functionsData[7].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-15">{{ functionsData[7].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-16">{{ functionsData[7].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-9">
              <div class="u-container-layout u-similar-container u-container-layout-9"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-9">

                                <v-icon x-large color="white"> {{ functionsData[8].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-17">{{ functionsData[8].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-18">{{ functionsData[8].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-10">
              <div class="u-container-layout u-similar-container u-container-layout-10"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-10">
                                <v-icon x-large color="white"> {{ functionsData[9].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-19">{{ functionsData[9].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-20">{{ functionsData[9].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-11">
              <div class="u-container-layout u-similar-container u-container-layout-11"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-11">
                                <v-icon x-large color="white"> {{ functionsData[10].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-21">{{ functionsData[10].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-22">{{ functionsData[10].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-12">
              <div class="u-container-layout u-similar-container u-container-layout-12"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-12">
                                <v-icon x-large color="white"> {{ functionsData[11].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-23">{{ functionsData[11].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-24">{{ functionsData[11].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-13">
              <div class="u-container-layout u-similar-container u-container-layout-13"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-13">
                                <v-icon x-large color="white"> {{ functionsData[12].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-25">{{ functionsData[12].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-26">{{ functionsData[12].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-14">
              <div class="u-container-layout u-similar-container u-container-layout-14"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-14">
                                <v-icon x-large color="white"> {{ functionsData[13].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-27">{{ functionsData[13].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-28">{{ functionsData[13].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-15">
              <div class="u-container-layout u-similar-container u-container-layout-15"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-15">
                                <v-icon x-large color="white"> {{ functionsData[14].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-29">{{ functionsData[14].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-30">{{ functionsData[14].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-16">
              <div class="u-container-layout u-similar-container u-container-layout-16"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-16">
                                <v-icon x-large color="white"> {{ functionsData[15].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-31">{{ functionsData[15].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-32">{{ functionsData[15].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-17">
              <div class="u-container-layout u-similar-container u-container-layout-17"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-17">
                                <v-icon x-large color="white"> {{ functionsData[16].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-33">{{ functionsData[16].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-34">{{ functionsData[16].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-18">
              <div class="u-container-layout u-similar-container u-container-layout-18"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-18">
                                <v-icon x-large color="white"> {{ functionsData[17].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-35">{{ functionsData[17].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-36">{{ functionsData[17].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-19">
              <div class="u-container-layout u-similar-container u-container-layout-19"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-19">
                                <v-icon x-large color="white"> {{ functionsData[18].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-37">{{ functionsData[18].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-38">{{ functionsData[18].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-20">
              <div class="u-container-layout u-similar-container u-container-layout-20"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-20">
                                <v-icon x-large color="white"> {{ functionsData[19].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-39">{{ functionsData[19].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-40">{{ functionsData[19].description }}</p>
              </div>
            </div>
            <div
              class="u-align-left u-container-style u-list-item u-radius-50 u-repeater-item u-shape-round u-video-cover u-white u-list-item-20">
              <div class="u-container-layout u-similar-container u-container-layout-20"><span
                class="u-align-center u-custom-color-1 u-icon u-icon-circle u-spacing-10 u-icon-20">

                                <v-icon x-large color="white"> {{ functionsData[20].icon }}</v-icon></span>
                <h4 class="u-align-center u-text u-text-default u-text-41">{{ functionsData[20].name }}</h4>
                <p class="u-align-center u-custom-item u-text u-text-42">{{ functionsData[20].description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="u-clearfix u-section-8" id="sec-partner">
        <parner-section/>
    </section>
    <section class="u-clearfix u-section-9" id="sec-contact">
        <contact-section/>
    </section>
    <v-scale-transition>
      <v-btn
        v-show="fab"
        v-scroll="onScroll"
        fab
        dark
        fixed
        bottom
        right
        color="#8BC7C7"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
  </div>
</template>

<script>

import Navigation from './Navigation'
import SystemFunctions from './SystemFunctions'
import AboutSection from './AboutSection'
import PricingSection from './PricingSection'
import ParnerSection from './PartnerSection'
import ContactSection from './ContactSection'
import HeaderSection from './HeaderSection'

export default {
  name: 'BasicHomePage',
  components: {
    HeaderSection,
    ContactSection,
    ParnerSection,
    SystemFunctions,
    PricingSection,
    AboutSection,
    Navigation
  },
  data: () => ({
    nameRules: [
      (v) => !!v || 'Campo nombre es requerido',
      (v) => (v && v.length >= 6) || 'Nombre requiere terner más de 6 caracteres'
    ],
    email: 'inneli@gmail.com',
    emailRules: [
      (v) => !!v || 'Campo email es requerido',
      (v) => /.+@.+\..+/.test(v) || 'E-mail requiere ser válido'
    ],
    textArea: '',
    textAreaRules: [
      (v) => !!v || 'Campo de texto es requerido',
      (v) => (v && v.length >= 10) || 'Mínimo de 10 carácteres'
    ],
    fab: null,
    color: '#8BC7C7',
    flat: null
  }),
  computed: {
    functionsData () {
      return [
        {
          show_plan: true,
          icon: 'mdi-home',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_shop'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_shop')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_shop')
        },
        {
          show_plan: true,
          icon: 'mdi-shopping',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_article'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_article')
        },
        {
          icon: 'mdi-currency-usd',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_vending'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_vending')
        },
        {
          icon: 'mdi-file-document',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_factures'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_facture')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_factures')
        },
        {
          icon: 'mdi-undo',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_refunds'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_refunds')
        },
        {
          icon: 'mdi-database',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_boxes'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_boxes')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_boxes')
        },
        {
          icon: 'mdi-tag',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_category'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_category')
        },
        {
          icon: 'mdi-tag-text-outline',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_mod'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },

          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_mod')
        },
        {
          icon: 'mdi-account-multiple',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_client'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_client')
        },
        {
          icon: 'mdi-cart',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_buy'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_buy')
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.supply_emit'),
          free: true,
          icon: 'mdi-home',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.supply_emit')
        },
        {
          name: this.$vuetify.lang.t('$vuetify.menu.supply_received'),
          free: true,
          icon: 'mdi-home',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.supply_received')
        },
        {
          icon: 'mdi-book-open-page-variant',
          name: this.$vuetify.lang.t('$vuetify.menu.account_classify'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.account_classify')
        },
        {
          icon: 'mdi-folder',
          name: this.$vuetify.lang.t('$vuetify.menu.accountings'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.accountings')
        },
        {
          icon: 'mdi-account-star',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_employer'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_users')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_employer')
        },
        {
          icon: 'mdi-account-switch',
          name: this.$vuetify.lang.t('$vuetify.access.access.manager_supplier'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.manager_supplier')
        },
        {
          icon: 'mdi-account-network',
          name: this.$vuetify.lang.t('$vuetify.menu.affiliate'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.affiliate')
        },
        {
          icon: 'mdi-home-map-marker',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.shop_online'),
          free: true,
          basic: {
            state: false,
            description: this.$vuetify.lang.t('$vuetify.access.access.warning_manager_shop')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.shop_online')
        },
        {
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.exchange_rate'),
          free: true,
          icon: 'mdi-bank',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.exchange_rate')
        },
        {
          icon: 'mdi-wallet',
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.tax_list'),
          free: true,
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.tax_list')
        },
        {
          show_plan: true,
          name: this.$vuetify.lang.t('$vuetify.menu.discount'),
          free: true,
          icon: 'mdi-cash-minus',
          basic: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          unicorn: {
            state: true,
            description: this.$vuetify.lang.t('$vuetify.unlimited')
          },
          description: this.$vuetify.lang.t('$vuetify.functions.descriptions.discounts')
        }
      ]
    }
  },
  async created () {
    const scripts = [
      '../../assets/js/nicepage/jquery.js',
      '../../assets/js/nicepage/nicepage.js'
    ]
    scripts.forEach(script => {
      const tag = document.createElement('script')
      tag.setAttribute('src', script)
      tag.setAttribute('class', 'nicepage-script')
      document.head.appendChild(tag)
    })
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 60
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    goToLogin () {
      this.$router.push({ name: 'login' })
    },
    goToRegister () {
      if (this.$route.params.hash) {
        const affiliateId = this.$route.params.hash.split('=')
        this.$router.push({ name: 'invitation_password', params: { ref: affiliateId[1] } })
      } else {
        this.$router.push({ name: 'register' })
      }
    }
  }
}
</script>

<!--<style scoped>-->
<!--@import "../../assets/css/nicepage/nicepage.css";-->
<!--@import "../../assets/css/nicepage/Billing-Money-App.css";-->
<!--</style>-->

<template>
  <div class="u-clearfix u-sheet u-valign-middle-lg u-sheet-1">
    <img class="u-hidden-md u-hidden-sm u-hidden-xs u-image u-opacity u-opacity-90 u-image-1"
         src="../../assets/images/partner1.jpg" data-image-width="1082" data-image-height="874">
    <div
      class="u-clearfix u-expanded-width-lg u-expanded-width-md u-expanded-width-sm u-expanded-width-xl u-gutter-30 u-layout-wrap u-layout-wrap-1">
      <div class="u-layout">
        <div class="u-layout-row">
          <div class="u-size-60">
            <div class="u-layout-row">
              <div
                class="u-align-left u-container-style u-layout-cell u-left-cell u-similar-fill u-size-60 u-layout-cell-1">
                <div
                  class="u-container-layout u-valign-middle-sm u-valign-middle-xs u-container-layout-1">
                  <h2 class="u-text u-text-1">Partners</h2>
                  <p class="u-align-justify u-large-text u-text u-text-variant u-text-2">
                    {{ $vuetify.lang.t('$vuetify.texts.partner_characteristic') }}<br>
                    {{ $vuetify.lang.t('$vuetify.texts.partner_characteristic2') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParnerSection',
  data () {
    return {
      dialog: false,
      videoId: 'i8IvvHJssWE'
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.pause()
      }
    }
  },
  methods: {
    ready (event) {
      this.player = event.target
    },
    playing () {
      // The player is playing a video.
    },
    change () {
      // when you change the value, the player will also change.
      // If you would like to change `playerVars`, please change it before you change `videoId`.
      // If `playerVars.autoplay` is 1, `loadVideoById` will be called.
      // If `playerVars.autoplay` is 0, `cueVideoById` will be called.
      this.videoId = 'another video id'
    },
    stop () {
      this.player.stopVideo()
    },
    pause () {
      this.player.pauseVideo()
    }
  }
}
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  transition: all 0.5s ease;

  .triangle {
    transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}
</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#partner {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

section {
  position: relative;
}
#partner {
    background-color: #8BC7C7;
}
#partner img {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
